import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Query } from '../query';
import { AutocompleteField } from '../autocomplete-field';
import { SKU } from '../../types/sku';
import { useAuth } from '../../hooks/use-auth';
import { ResponseData, ListResponse } from '../../types/axios';
import { useAxios } from '../../hooks/use-axios';
import { FilterValue } from '../../types/filter';
import { FilterOperator } from '../../utils/filter-operators';
import { User } from '../../types/user';
import { MAX_PAGE_SIZE } from '../../utils/utils';

interface ItemsFilterProps {
  disabled: boolean;
  onQueryChange: (newQuery: string) => void;
  onFilterChange: (newFilterValue: FilterValue) => void;
  query: string;
  filters: FilterValue[];
}

enum FilterProperty {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SKU = 'sku',
  OWNER = 'owner.email',
}

export const ItemsFilter: FC<ItemsFilterProps> = (props) => {
  const { disabled, onQueryChange, onFilterChange, query, filters } = props;

  const { tenant } = useAuth();
  const { axios } = useAxios();

  const {
    data: skus,
    isLoading: isSkusLoading,
    error: skusError,
  } = useQuery(
    ['items-skus', tenant],
    async () => {
      const url = '/skus';
      const {
        data: {
          data: { data },
        },
      } = await axios.get<ResponseData<ListResponse<SKU>>>(url, {
        params: {
          start: 0,
          length: MAX_PAGE_SIZE,
          'globalFilter[tenant]': tenant?.id,
        },
      });

      return data;
    },
    {
      initialData: [] as SKU[],
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: users,
    isLoading: isUsersLoading,
    error: usersError,
  } = useQuery(
    ['items-users', tenant],
    async () => {
      const url = '/users';
      const {
        data: {
          data: { data },
        },
      } = await axios.get<ResponseData<ListResponse<User>>>(url, {
        params: {
          start: 0,
          length: MAX_PAGE_SIZE,
          tenantId: tenant?.id,
        },
      });

      return data;
    },
    {
      initialData: [] as User[],
      refetchOnWindowFocus: false,
    },
  );

  const handleSkuChange = (_e: any, value?: SKU) => {
    onFilterChange({
      property: FilterProperty.SKU,
      value: value?.id ?? null,
      operator: FilterOperator.EQUAL,
    });
  };

  const handleUserChange = (_e: any, value?: string | User) => {
    onFilterChange({
      property: FilterProperty.OWNER,
      value: (typeof value === 'string' ? value : value?.email) ?? null,
      operator: FilterOperator.CONTAINS,
    });
  };

  return (
    <>
      <div>
        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 2,
            gridTemplateColumns: {
              sm: '1fr auto auto',
              xs: 'auto',
            },
            justifyItems: 'flex-start',
            p: 3,
          }}
        >
          <Query disabled={disabled} onChange={onQueryChange} value={query} />
          <AutocompleteField
            label=""
            options={skus || []}
            getOptionLabel={(option) => option.title || `[${option.id}]`}
            error={Boolean(skusError)}
            filterSelectedOptions
            clearOnEscape
            loading={isSkusLoading}
            onChange={handleSkuChange}
            placeholder="Select SKU"
            value={skus?.find(
              (sku) => sku.id === filters?.find((f) => f.property === FilterProperty.SKU)?.value,
            ) || null}
            sx={{
              width: 300,
              '& input': {
                height: '30px !important',
                fontSize: '1em !important',
              },
            }}
          />
          <AutocompleteField
            label=""
            options={users || []}
            getOptionLabel={(option) => option.email || option}
            error={Boolean(usersError)}
            filterSelectedOptions
            clearOnEscape
            loading={isUsersLoading}
            onChange={handleUserChange}
            placeholder="Search user"
            value={
              users?.find(
                (user) =>
                  user.email === filters?.find((f) => f.property === FilterProperty.OWNER)?.value,
              ) || filters?.find((f) => f.property === FilterProperty.OWNER)?.value || null
            }
            freeSolo
            sx={{
              width: 300,
              '& input': {
                height: '30px !important',
                fontSize: '1em !important',
              },
            }}
          />
        </Box>
      </div>
    </>
  );
};

ItemsFilter.propTypes = {
  disabled: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  query: PropTypes.string,
  filters: PropTypes.array,
};
