import { AxiosInstance } from 'axios';
import { storage } from '../utils/storage';
import { Tenant, UserTenant, UserTenants } from '../types/tenant';
import { ExternalUser, User } from '../types/user';
import axios from '../utils/axios';
import { ListResponse, ResponseData } from '../types/axios';
import { compareDistinctArray, MAX_PAGE_SIZE } from '../utils/utils';
import logger from '../utils/logger';
import { getCorrectTenants } from '../utils/tenants-helper';

interface LoginRequest {
  email: string;
  password: string;
}

interface AuthTokens {
  idToken: string;
  refreshToken: string;
}

type LoginResponse = AuthTokens;

type RefreshResponse = AuthTokens;

type UserResponse = User;

type TenantsResponse = ListResponse<Tenant>;

class AuthApi {
  async login(data: LoginRequest): Promise<AuthTokens> {
    logger('[API/AUTH/LOGIN]');
    let result = null;
    try {
      const { data: loginResponse } = await axios.post<ResponseData<LoginResponse>>(
        '/authentication/login',
        data,
      );
      result = loginResponse?.data;
    } catch (err) {
      logger('[API/AUTH/LOGIN/ERROR]', err.response?.data?.error?.message);
      throw new Error(err.response?.data?.error?.message ?? err);
    }

    return result;
  }

  async me(idToken: string, refresh: boolean = false): Promise<ExternalUser> {
    const headerAuth = {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    };

    let user: ExternalUser = null;
    try {
      // Get user from server
      const { data: userResponse } = await axios.get<ResponseData<UserResponse>>(
        '/users/me',
        headerAuth,
      );

      user = userResponse?.data;

      if (!refresh) {
        // Get tenants from local storage
        const tenantsJson = storage.tenants.get();
        if (tenantsJson && typeof tenantsJson === 'string') {
          const { userId, tenants }: UserTenants = JSON.parse(tenantsJson);

          if (
            userId === user.id &&
            (user.permissions.ADMIN || compareDistinctArray(tenants, Object.keys(user.permissions)))
          ) {
            user.tenants = tenants;
          }
        }
      }

      // Get tenants from server
      if (refresh || !user.tenants) {
        const { data: tenantsResponse } = await axios.get<ResponseData<TenantsResponse>>(
          '/users/me/tenants',
          {
            params: {
              length: MAX_PAGE_SIZE,
            },
            ...headerAuth,
          },
        );

        const { data: tenants } = tenantsResponse?.data;

        user.tenants = tenants;
      }

      user.tenants = getCorrectTenants(user.tenants, user.permissions);

      // Get selected tenant
      const tenantJson = storage.tenant.get();
      if (tenantJson && typeof tenantJson === 'string') {
        const { userId, tenant }: UserTenant = JSON.parse(tenantJson);

        logger('[ME/TENANT] Selected tenant', tenant, user);
        const correctTenant = user.tenants.find((t) => t.id === tenant.id);
        if (
          correctTenant &&
          userId === user.id &&
          (user.permissions.ADMIN || user.permissions[tenant.id])
        ) {
          logger('[ME/TENANT/SELECTED]', user, tenant);
          user.tenant = correctTenant;
        }
      }
      // Select default tenant
      if (!user.tenant) {
        logger('[ME/TENANT] Not selected tenant');
        [user.tenant] = user.tenants;
      }
    } catch (err) {
      logger('[API/AUTH/ME/ERROR]', err);
      throw new Error(err.response?.data?.error?.message ?? err.message);
    }

    return user;
  }

  async refresh(refreshToken: string, ax: AxiosInstance): Promise<AuthTokens> {
    logger('[API/AUTH/REFRESH]');
    const {
      data: { data },
    } = await ax.post<ResponseData<RefreshResponse>>('/authentication/refreshToken', {
      refreshToken,
    });

    return data as AuthTokens;
  }
}

export const authApi = new AuthApi();
