import type { FC } from 'react';
import { ETemplateType } from '../templates/types';
import { Items as ItemsDefault } from '../templates/default/containers/items';
import { Items as ItemsAuthentique } from '../templates/authentique/containers/items';
import { Items as ItemsVera } from '../templates/vera-admin/containers/items';
import { Items as ItemsVeraOperator } from '../templates/vera-operator/containers/items';

export const Items: FC = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <ItemsDefault />;
    case ETemplateType.AUTHENTIQUE:
      return <ItemsAuthentique />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <ItemsVera />;
    case ETemplateType.VERA_OPERATOR:
      return <ItemsVeraOperator />;
  }
};
