import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import {
  Pagination as PaginationDefault,
  DEFAULT_PAGE_SIZE as DPS_Default,
} from '../templates/default/components/pagination';
import {
  Pagination as PaginationVeraAIOperator,
  DEFAULT_PAGE_SIZE as DPS_vera_ai_operator,
} from '../templates/vera-operator/components/pagination';

const getDefaultPageSize = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return DPS_Default;
    case ETemplateType.VERA_OPERATOR:
      return DPS_vera_ai_operator;
  }
};

export const DEFAULT_PAGE_SIZE = getDefaultPageSize();

interface PaginationProps {
  disabled: boolean;
  onPageChange: (newPage: number) => void;
  page: number;
  rowsCount: number;
  pageSize?: number;
  title?: string;
}

export const Pagination: FC<PaginationProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <PaginationDefault {...props} />;
    case ETemplateType.VERA_OPERATOR:
      return <PaginationVeraAIOperator {...props} />;
  }
};

Pagination.defaultProps = {
  disabled: false,
  page: 1,
  rowsCount: 1,
};

Pagination.propTypes = {
  disabled: PropTypes.bool,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsCount: PropTypes.number,
  pageSize: PropTypes.number,
  title: PropTypes.string,
};
