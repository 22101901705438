import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ETemplateType } from '../templates/types';
import { Logo as LogoDefault } from '../templates/default/components/logo';
import { Logo as LogoAuthentique } from '../templates/authentique/components/logo';
import { Logo as LogoVera } from '../templates/vera-admin/components/logo';
import { Logo as LogoEbay } from '../templates/ebay/components/logo';

interface LogoProps {
  emblemOnly?: boolean;
  variant: 'light' | 'dark';
  size?: 'default' | 'large';
}

export const Logo: FC<LogoProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <LogoDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <LogoAuthentique {...props} />;
    case ETemplateType.EBAY:
      return <LogoEbay {...props} />;
    case ETemplateType.VERA_ADMIN:
      return <LogoVera {...props} />;
  }
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark',
  size: 'default',
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['default', 'large']),
};
