import { useCallback, useEffect, useState } from 'react';
import type { FC, MouseEvent } from 'react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { ItemsTable } from '../../../components/item/items-table';
import type { Item } from '../../../types/item';
import { useAxios } from '../../../hooks/use-axios';
import { useAuth } from '../../../hooks/use-auth';
import type { ListResponse, ResponseData } from '../../../types/axios';
import { getHelmetTitle } from '../../../utils/utils';
import { FilterValue, Sort } from '../../../types/filter';
import { SUPER_ADMIN_TENANT } from '../../../types/tenant';
import { useScanner } from '../hooks/use-scanner';
import { DEFAULT_PAGE_SIZE } from '../components/pagination';
import { LoadingDialog } from '../components/loading-dialog';
import { ItemsNavbar } from '../components/item/items-navbar';
import { ScannedItem } from '../types/scanned-item';
import { permaLogger } from '../../../utils/logger';
import { ESocketEvent, MessageAction, ScanMessage } from '../contexts/scanner-context';

type ItemsData = ListResponse<Item>;

interface Controller {
  filters: Array<FilterValue>;
  page: number;
  sort: Sort;
  sortBy: string;
}

const DEFAULT_CONTROLLER: Controller = {
  filters: [],
  page: 0,
  sort: 'desc',
  sortBy: 'createdAt',
};

export const Items: FC = () => {
  const [controller, setController] = useState<Controller>(DEFAULT_CONTROLLER);

  const { tenant, user } = useAuth();
  const { socket, loadItem, isItemLoading, calibratedAt } = useScanner();
  const { axios } = useAxios();
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery<ItemsData>(['items', controller], async () => {
    const params: any = {
      start: controller.page * DEFAULT_PAGE_SIZE,
      length: DEFAULT_PAGE_SIZE,
      sort: controller.sort.toUpperCase(),
      sortBy: controller.sortBy,
      'globalFilter[isProtected]': true,
      'globalFilter[owner]': user?.id,
    };

    if (tenant?.id !== SUPER_ADMIN_TENANT.id) {
      params['globalFilter[tenant]'] = tenant?.id;
    }

    const url = `/items`;

    const { data: response } = await axios.get<ResponseData<ItemsData>>(url, { params });

    return response?.data;
  });

  const handlePageChange = (newPage: number): void => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handleSortChange = (event: MouseEvent<HTMLElement>, property: string): void => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const messageHandler = useCallback(
    ({ action, data: messageData }: ScanMessage) => {
      if (action === MessageAction.ItemScan && !isItemLoading) {
        if (
          process.env.REACT_APP_CALIBRATION_OPTIONAL === '1' ||
          calibratedAt?.getFullYear() >= 2024
        ) {
          loadItem(messageData as ScannedItem);
        } else {
          permaLogger('[jobs/list] No calibration data found. Please calibrate the camera first.');
          toast.error('No calibration data found. Please calibrate the camera first.');
        }
      }
    },
    [loadItem],
  );

  useEffect(() => {
    if (socket) {
      socket.on(ESocketEvent.ScanEvent, messageHandler);
    }

    return () => {
      socket?.off(ESocketEvent.ScanEvent, messageHandler);
    };
  }, [socket, messageHandler]);

  useEffect(() => {
    if (tenant && !tenant?.publicMetadata?.demoMode?.enabled) {
      navigate('/', { replace: true });
    }
  }, [tenant]);

  return (
    <>
      <Helmet>
        <title>Item: List | {getHelmetTitle()}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          pt: '120px',
        }}
      >
        <ItemsNavbar />
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: '0 !important',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <ItemsTable
              error={error?.toString()}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              items={data?.data}
              itemsCount={data?.recordsFiltered}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Box>
        </Container>
      </Box>
      <LoadingDialog open={isItemLoading} title="Loading item" />
    </>
  );
};
