import { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ButtonBase,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import type { SxProps } from '@material-ui/system';
import { usePopover } from '../hooks/use-popover';
import { Selector as SelectorIcon } from '../icons/selector';
import { SUPER_ADMIN_TENANT, Tenant } from '../types/tenant';
import { useAxios } from '../hooks/use-axios';
import { useAuth } from '../hooks/use-auth';
import logger from '../utils/logger';
import { ListResponse, ResponseData } from '../types/axios';
import { MAX_PAGE_SIZE } from '../utils/utils';

interface TenantPopoverProps {
  currentTenant: Tenant;
  onTenantChange: (tenantId: string) => void;
  tenants: Tenant[];
  sx?: SxProps;
}

export const TenantPopover: FC<TenantPopoverProps> = (props) => {
  const { currentTenant, tenants, onTenantChange, sx, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { token, refreshTenants } = useAuth();
  const { axios } = useAxios();

  const { data: userTenants } = useQuery(
    ['active-tenants', token],
    async () => {
      const url = '/users/me/tenants';

      const {
        data: { data: response },
      } = await axios.get<ResponseData<ListResponse<Tenant>>>(url, {
        params: { length: MAX_PAGE_SIZE },
      });

      if (tenants.some((t) => t.id === SUPER_ADMIN_TENANT.id)) {
        return [SUPER_ADMIN_TENANT, ...response?.data];
      }

      return response?.data;
    },
    {
      onSuccess: (data) => {
        if (data?.length !== tenants?.length) {
          logger('Tenants refreshed');
          refreshTenants();
        }
      },
      initialData: [],
    },
  );

  const handleTenantChange = (tenantId: string): void => {
    handleClose();
    onTenantChange?.(tenantId);
  };

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: 'flex',
          p: 1,
          minWidth: 180,
          maxWidth: 300,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          },
          ...sx,
        }}
        title={currentTenant.title}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            whiteSpace: 'nowrap',
            color: 'primary.contrastText',
            mr: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="subtitle2"
        >
          {currentTenant.title}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <SelectorIcon fontSize="small" />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 200 },
        }}
      >
        <List>
          {userTenants?.map((tenant) => (
            <div key={tenant.id}>
              <ListItemButton
                selected={tenant.id === currentTenant.id}
                onClick={() => handleTenantChange(tenant.id)}
              >
                <ListItemText primary={tenant.title} />
              </ListItemButton>
              {tenant.id === SUPER_ADMIN_TENANT.id && tenants.length > 1 && <Divider />}
            </div>
          ))}
        </List>
      </Popover>
    </>
  );
};

TenantPopover.propTypes = {
  // @ts-ignore
  currentTenant: PropTypes.object.isRequired,
  onTenantChange: PropTypes.func,
  tenants: PropTypes.array.isRequired,
  sx: PropTypes.object,
};
