import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import { useQuery } from 'react-query';
import { Query } from '../../../../components/query';
import { DateRangeField } from '../../../../components/date-range-field';
import { JobsBadges } from '../../../../components/job/jobs-badges';
import { FilterOperator } from '../../../../utils/filter-operators';
import { Job } from '../../../../types/job';
import { useAuth } from '../../../../hooks/use-auth';
import { useAxios } from '../../../../hooks/use-axios';
import { ResponseData, ListResponse } from '../../../../types/axios';
import { AutocompleteField } from '../../../../components/autocomplete-field';
import { SKU } from '../../../../types/sku';
import { User } from '../../../../types/user';
import { FilterValue } from '../../../../types/filter';
import { EJobsView, getJobsRequestStatusParams } from '../../../../utils/jobs-helper';
import { TenantType } from '../../../../types/tenant';
import { MAX_PAGE_SIZE } from '../../../../utils/utils';

interface JobsFilterProps {
  disabled: boolean;
  onRangeChange: (newRange: DateRange<Date>) => void;
  onQueryChange: (newQuery: string) => void;
  onViewChange: (newView: EJobsView) => void;
  onFilterChange: (newFilterValue: FilterValue) => void;
  range: DateRange<Date>;
  query: string;
  view: EJobsView;
  filters?: FilterValue[];
}

interface View {
  label: string;
  value: EJobsView;
  forTypes?: TenantType[];
}

enum FilterProperty {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SKU = 'item.sku',
  USER = 'user.email',
}

const views: View[] = [
  {
    label: 'All',
    value: EJobsView.ALL,
    forTypes: [TenantType.AUTHENTICATION, TenantType.IDENTIFICATION],
  },
  {
    label: 'Protected',
    value: EJobsView.PROTECTED,
    forTypes: [TenantType.AUTHENTICATION],
  },
  {
    label: 'Unable To Protect',
    value: EJobsView.UNABLE_TO_PROTECT,
    forTypes: [TenantType.AUTHENTICATION],
  },
  {
    label: 'Registered',
    value: EJobsView.REGISTERED,
    forTypes: [TenantType.IDENTIFICATION],
  },
  {
    label: 'Unable To Register',
    value: EJobsView.UNABLE_TO_REGISTER,
    forTypes: [TenantType.IDENTIFICATION],
  },
  {
    label: 'Verified',
    value: EJobsView.VERIFIED,
    forTypes: [TenantType.AUTHENTICATION],
  },
  {
    label: 'Unable To Verify',
    value: EJobsView.UNABLE_TO_VERIFY,
    forTypes: [TenantType.AUTHENTICATION],
  },
  {
    label: 'Not Verified',
    value: EJobsView.NOT_VERIFIED,
    forTypes: [TenantType.AUTHENTICATION],
  },
  {
    label: 'Identified',
    value: EJobsView.IDENTIFIED,
    forTypes: [TenantType.IDENTIFICATION],
  },
  {
    label: 'Unable To Identify',
    value: EJobsView.UNABLE_TO_IDENTIFY,
    forTypes: [TenantType.IDENTIFICATION],
  },
  {
    label: 'Not Identified',
    value: EJobsView.NOT_IDENTIFIED,
    forTypes: [TenantType.IDENTIFICATION],
  },
  {
    label: 'Reported',
    value: EJobsView.REPORTED,
    forTypes: [TenantType.AUTHENTICATION, TenantType.IDENTIFICATION],
  },
];

const getNow = (startOfDay: boolean = false, addDays: number = 0): Date => {
  const now = new Date();
  now.setHours(23, 59, 59);
  if (startOfDay) {
    now.setHours(0, 0, 0, 0);
  }
  return new Date(now.setDate(now.getDate() + addDays));
};

const getTodayRange = (): DateRange<Date> => [getNow(true), getNow()];

const getWeekRange = (): DateRange<Date> => [getNow(true, -6), getNow()];

const getMonthRange = (): DateRange<Date> => [getNow(true, -30), getNow()];

export const getDefaultRange = getWeekRange;

export const JobsFilter: FC<JobsFilterProps> = (props) => {
  const {
    disabled,
    onRangeChange,
    onQueryChange,
    onViewChange,
    onFilterChange,
    range,
    view,
    query,
    filters,
  } = props;

  const { axios } = useAxios();
  const { tenant, getTenantTypes } = useAuth();
  // Processed
  const { data: allCount, isLoading: isAllCountLoading } = useQuery(
    ['jobs-all', tenant, range],
    async () => {
      const url = '/jobs';
      const {
        data: {
          data: { recordsFiltered },
        },
      } = await axios.get<ResponseData<ListResponse<Job>>>(url, {
        params: {
          start: 0,
          length: 0,
          'globalFilter[tenant]': tenant?.id,
          'globalFilter[startedAt]': {
            operator: FilterOperator.BETWEEN,
            value: [range[0].toISOString(), new Date(range[1].setHours(23, 59, 59)).toISOString()],
          },
          onlyCount: true,
          ...getJobsRequestStatusParams(),
        },
      });

      return recordsFiltered;
    },
  );

  const {
    data: skus,
    isLoading: isSkusLoading,
    error: skusError,
  } = useQuery(
    ['jobs-skus', tenant],
    async () => {
      const url = '/skus';
      const {
        data: {
          data: { data },
        },
      } = await axios.get<ResponseData<ListResponse<SKU>>>(url, {
        params: {
          start: 0,
          length: MAX_PAGE_SIZE,
          'globalFilter[tenant]': tenant?.id,
        },
      });

      return data;
    },
    {
      initialData: [] as SKU[],
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: users,
    isLoading: isUsersLoading,
    error: usersError,
  } = useQuery(
    ['jobs-users', tenant],
    async () => {
      const url = '/users';
      const {
        data: {
          data: { data },
        },
      } = await axios.get<ResponseData<ListResponse<User>>>(url, {
        params: {
          start: 0,
          length: MAX_PAGE_SIZE,
          tenantId: tenant?.id,
        },
      });

      return data;
    },
    {
      initialData: [] as User[],
      refetchOnWindowFocus: false,
    },
  );

  const handleTodayClick = () => {
    onRangeChange(getTodayRange());
  };

  const handleWeekClick = () => {
    onRangeChange(getWeekRange());
  };

  const handleMonthClick = () => {
    onRangeChange(getMonthRange());
  };

  const handleSkuChange = (_e: any, value?: SKU) => {
    onFilterChange({
      property: FilterProperty.SKU,
      value: value?.id ?? null,
      operator: FilterOperator.EQUAL,
    });
  };

  const handleUserChange = (_e: any, value?: string | User) => {
    onFilterChange({
      property: FilterProperty.USER,
      value: (typeof value === 'string' ? value : value?.email) ?? null,
      operator: FilterOperator.CONTAINS,
    });
  };

  return (
    <>
      <CardHeader
        title={
          <Box
            sx={{
              display: 'flex',
              gridGap: '0.5em',
              mr: 1,
            }}
          >
            {isAllCountLoading ? (
              <Skeleton variant="text" width={30} />
            ) : (
              <Chip
                size="small"
                variant="filled"
                label={allCount}
                sx={{ borderRadius: 16, color: 'primary.contrastText', background: '#506176' }}
              />
            )}
            <Typography>{allCount !== 1 ? 'Events' : 'Event'}</Typography>
          </Box>
        }
        action={
          <Box
            sx={{
              display: 'flex',
              gridGap: '1em',
            }}
          >
            <Button variant="outlined" onClick={handleTodayClick}>
              Today
            </Button>
            <Button variant="outlined" onClick={handleWeekClick}>
              Last 7 days
            </Button>
            <Button variant="outlined" onClick={handleMonthClick}>
              Last 30 days
            </Button>
            <DateRangeField fullWidth onChange={() => {}} onAccept={onRangeChange} value={range} />
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <JobsBadges range={range} />
      </CardContent>
      <Divider />
      <Box
        sx={{
          px: {
            sm: 3,
          },
        }}
      >
        <Tabs
          onChange={(_event, value) => onViewChange?.(value)}
          allowScrollButtonsMobile
          value={view}
          variant="scrollable"
        >
          {views
            .filter((v) => !v.forTypes || v.forTypes.some((t) => getTenantTypes().includes(t)))
            .map((option) => (
              <Tab
                disabled={disabled}
                key={option.label}
                label={option.label}
                value={option.value}
              />
            ))}
        </Tabs>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            sm: '1fr auto auto',
            xs: 'auto',
          },
          justifyItems: 'flex-start',
          p: 3,
        }}
      >
        <Query disabled={disabled} onChange={onQueryChange} value={query} />
        <AutocompleteField
          label=""
          options={skus || []}
          getOptionLabel={(option) => option.title || `[${option.id}]`}
          error={Boolean(skusError)}
          filterSelectedOptions
          clearOnEscape
          loading={isSkusLoading}
          onChange={handleSkuChange}
          placeholder="Select SKU"
          value={
            skus?.find(
              (sku) => sku.id === filters?.find((f) => f.property === FilterProperty.SKU)?.value,
            ) || null
          }
          sx={{
            width: 300,
            '& input': {
              height: '30px !important',
              fontSize: '1em !important',
            },
          }}
        />
        <AutocompleteField
          label=""
          options={users || []}
          getOptionLabel={(option) => option.email || option}
          error={Boolean(usersError)}
          filterSelectedOptions
          clearOnEscape
          loading={isUsersLoading}
          onChange={handleUserChange}
          placeholder="Search user"
          value={
            users?.find(
              (user) =>
                user.email === filters?.find((f) => f.property === FilterProperty.USER)?.value,
            ) ||
            filters?.find((f) => f.property === FilterProperty.USER)?.value ||
            null
          }
          freeSolo
          sx={{
            width: 300,
            '& input': {
              height: '30px !important',
              fontSize: '1em !important',
            },
          }}
        />
      </Box>
    </>
  );
};

JobsFilter.propTypes = {
  disabled: PropTypes.bool,
  onRangeChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  range: PropTypes.any,
  query: PropTypes.string,
  view: PropTypes.any,
  filters: PropTypes.any,
};
