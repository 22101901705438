import { ETemplateType } from './templates/types';
import {
  lightPrimary as lightPrimaryDefault,
  lightSecondary as lightSecondaryDefault,
  lightError as lightErrorDefault,
  lightWarning as lightWarningDefault,
  lightInfo as lightInfoDefault,
  lightSuccess as lightSuccessDefault,
  lightBackground as lightBackgroundDefault,
  lightText as lightTextDefault,
  lightNeutral as lightNeutralDefault,
  darkPrimary as darkPrimaryDefault,
  darkSecondary as darkSecondaryDefault,
  darkError as darkErrorDefault,
  darkWarning as darkWarningDefault,
  darkInfo as darkInfoDefault,
  darkSuccess as darkSuccessDefault,
  darkBackground as darkBackgroundDefault,
  darkText as darkTextDefault,
  darkNeutral as darkNeutralDefault,
} from './templates/default/colors';
import {
  lightPrimary as lightPrimaryVeraAIOperator,
  lightSecondary as lightSecondaryVeraAIOperator,
  lightError as lightErrorVeraAIOperator,
  lightWarning as lightWarningVeraAIOperator,
  lightInfo as lightInfoVeraAIOperator,
  lightSuccess as lightSuccessVeraAIOperator,
  lightBackground as lightBackgroundVeraAIOperator,
  lightText as lightTextVeraAIOperator,
  lightNeutral as lightNeutralVeraAIOperator,
  darkPrimary as darkPrimaryVeraAIOperator,
  darkSecondary as darkSecondaryVeraAIOperator,
  darkError as darkErrorVeraAIOperator,
  darkWarning as darkWarningVeraAIOperator,
  darkInfo as darkInfoVeraAIOperator,
  darkSuccess as darkSuccessVeraAIOperator,
  darkBackground as darkBackgroundVeraAIOperator,
  darkText as darkTextVeraAIOperator,
  darkNeutral as darkNeutralVeraAIOperator,
} from './templates/vera-operator/colors';

interface TemplatesColor {
  colorDefault: any;
  colorVeraAIOperator: any;
}

const getTemplateColor = ({ colorDefault, colorVeraAIOperator }: TemplatesColor) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return colorDefault;
    case ETemplateType.VERA_OPERATOR:
      return colorVeraAIOperator;
  }
};

export const lightPrimary = getTemplateColor({
  colorDefault: lightPrimaryDefault,
  colorVeraAIOperator: lightPrimaryVeraAIOperator,
});

export const lightSecondary = getTemplateColor({
  colorDefault: lightSecondaryDefault,
  colorVeraAIOperator: lightSecondaryVeraAIOperator,
});

export const lightError = getTemplateColor({
  colorDefault: lightErrorDefault,
  colorVeraAIOperator: lightErrorVeraAIOperator,
});

export const lightWarning = getTemplateColor({
  colorDefault: lightWarningDefault,
  colorVeraAIOperator: lightWarningVeraAIOperator,
});

export const lightInfo = getTemplateColor({
  colorDefault: lightInfoDefault,
  colorVeraAIOperator: lightInfoVeraAIOperator,
});

export const lightSuccess = getTemplateColor({
  colorDefault: lightSuccessDefault,
  colorVeraAIOperator: lightSuccessVeraAIOperator,
});

export const lightBackground = getTemplateColor({
  colorDefault: lightBackgroundDefault,
  colorVeraAIOperator: lightBackgroundVeraAIOperator,
});

export const lightText = getTemplateColor({
  colorDefault: lightTextDefault,
  colorVeraAIOperator: lightTextVeraAIOperator,
});

export const lightNeutral = getTemplateColor({
  colorDefault: lightNeutralDefault,
  colorVeraAIOperator: lightNeutralVeraAIOperator,
});

export const darkPrimary = getTemplateColor({
  colorDefault: darkPrimaryDefault,
  colorVeraAIOperator: darkPrimaryVeraAIOperator,
});

export const darkSecondary = getTemplateColor({
  colorDefault: darkSecondaryDefault,
  colorVeraAIOperator: darkSecondaryVeraAIOperator,
});

export const darkError = getTemplateColor({
  colorDefault: darkErrorDefault,
  colorVeraAIOperator: darkErrorVeraAIOperator,
});

export const darkWarning = getTemplateColor({
  colorDefault: darkWarningDefault,
  colorVeraAIOperator: darkWarningVeraAIOperator,
});

export const darkInfo = getTemplateColor({
  colorDefault: darkInfoDefault,
  colorVeraAIOperator: darkInfoVeraAIOperator,
});

export const darkSuccess = getTemplateColor({
  colorDefault: darkSuccessDefault,
  colorVeraAIOperator: darkSuccessVeraAIOperator,
});

export const darkBackground = getTemplateColor({
  colorDefault: darkBackgroundDefault,
  colorVeraAIOperator: darkBackgroundVeraAIOperator,
});

export const darkText = getTemplateColor({
  colorDefault: darkTextDefault,
  colorVeraAIOperator: darkTextVeraAIOperator,
});

export const darkNeutral = getTemplateColor({
  colorDefault: darkNeutralDefault,
  colorVeraAIOperator: darkNeutralVeraAIOperator,
});
