import { useState, FC } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EItemVisibility, Item } from '../../types/item';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useAxios } from '../../hooks/use-axios';
import { SKU } from '../../types/sku';
import { ListResponse, ResponseData } from '../../types/axios';
import { MAX_PAGE_SIZE } from '../../utils/utils';

const DEFAULT_SKU: Pick<SKU, 'id' | 'title' | 'publicMetadata'> = {
  id: 'unassigned',
  title: 'Unassigned',
};

interface ItemValues {
  title: string;
  subtitle?: string;
  externalId?: string;
  skuId?: string;
  publicMetadata: {
    widthCm?: number;
    heightCm?: number;
  };
  visibility: EItemVisibility;
}

interface ItemCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ItemCreateDialog: FC<ItemCreateDialogProps> = (props) => {
  const { open, onClose, ...other } = props;
  const [isSizeDisabled, setIsSizeDisabled] = useState(false);
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const queryClient = useQueryClient();

  const {
    data: skus,
    isLoading: isSkusLoading,
    isError: isSkusError,
  } = useQuery(['skus', tenant], async () => {
    if (!tenant) {
      throw new Error('No tenant');
    }

    const url = '/skus';

    const params = {
      length: MAX_PAGE_SIZE,
      'globalFilter[tenant]': tenant.id,
    };

    const {
      data: { data: response },
    } = await axios.get<ResponseData<ListResponse<SKU>>>(url, { params });

    return response?.data;
  });

  const mutation = useMutation(
    async ({ skuId, ...vals }: ItemValues) => {
      if (!tenant) {
        throw new Error('No tenant');
      }

      const vertical = tenant.allowedVerticals[0]?.uniqueId ?? 'vertical_documents';

      const data: any = {
        tenantId: tenant.id,

        item: {
          ...vals,
          publicMetadata: {
            ...vals.publicMetadata,
            widthCm: Number(vals.publicMetadata.widthCm),
            heightCm: Number(vals.publicMetadata.heightCm),
            vertical,
          },
        },
      };

      if (skuId && skuId !== DEFAULT_SKU.id) {
        data.skuId = skuId;
      }

      const url = '/items';

      return axios.post<ResponseData<Item>>(url, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('items');
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      subtitle: '',
      externalId: '',
      skuId: DEFAULT_SKU.id,
      publicMetadata: {
        widthCm: 7.5,
        heightCm: 10,
      },
      visibility: EItemVisibility.PRIVATE,
      submit: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().max(255).required('Title is required'),
      subtitle: Yup.string().optional(),
      externalId: Yup.string().optional(),
      skuId: Yup.string().optional(),
      publicMetadata: Yup.object().shape({
        widthCm: Yup.number().min(0).optional(),
        heightCm: Yup.number().min(0).optional(),
      }),
      visibility: Yup.string().optional(),
    }),
    onReset: () => {
      setIsSizeDisabled(false);
    },
    onSubmit: async (values, helpers) => {
      const { submit, ...vals } = values;
      mutation.mutate(vals, {
        onSuccess: () => {
          onClose();

          helpers.setStatus({ success: true });

          toast.success('Item created');
        },
        onError: (err: any) => {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err?.response?.data?.error?.message || err?.message });
        },
        onSettled: () => {
          helpers.setSubmitting(false);
        },
      });
    },
  });

  const handleSKUChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);

    const skuId = e.target.value;

    const sku = skus?.find((s) => s.id === skuId);

    let skuHaveSize = false;
    if (sku?.publicMetadata?.widthCm && sku?.publicMetadata?.heightCm) {
      formik.setFieldValue('publicMetadata.widthCm', sku.publicMetadata.widthCm);
      formik.setFieldValue('publicMetadata.heightCm', sku.publicMetadata.heightCm);
      skuHaveSize = true;
    }

    setIsSizeDisabled(skuHaveSize);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm(),
      }}
      fullWidth
      {...other}
    >
      <DialogTitle>Create Item</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Title */}
            <InputField
              error={Boolean(formik.touched.title && formik.errors.title)}
              fullWidth
              helperText={formik.touched.title && formik.errors.title}
              label="Title"
              name="title"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.title}
            />
          </Grid>

          <Grid item xs={12}>
            {/* Subtitle */}
            <InputField
              error={Boolean(formik.touched.subtitle && formik.errors.subtitle)}
              fullWidth
              helperText={formik.touched.subtitle && formik.errors.subtitle}
              label="Subtitle"
              name="subtitle"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.subtitle}
            />
          </Grid>

          <Grid item xs={12}>
            {/* External ID */}
            <InputField
              error={Boolean(formik.touched.externalId && formik.errors.externalId)}
              fullWidth
              helperText={formik.touched.externalId && formik.errors.externalId}
              label="External ID"
              name="externalId"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.externalId}
            />
          </Grid>

          {/* SKU */}
          <Grid item xs={12}>
            <InputField
              error={Boolean((formik?.touched?.skuId && formik?.errors?.skuId) || isSkusError)}
              fullWidth
              helperText={
                (formik?.touched?.skuId && formik?.errors?.skuId) ||
                (isSkusError && 'Cannot load SKUs')
              }
              label="SKU"
              name="skuId"
              onBlur={formik.handleBlur}
              onChange={handleSKUChange}
              value={formik.values.skuId}
              select
              disabled={isSkusLoading}
            >
              <MenuItem value={DEFAULT_SKU.id}>
                <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                  {DEFAULT_SKU.title}
                </Typography>
              </MenuItem>
              {(skus ?? []).map((sku) => (
                <MenuItem key={sku.id} value={sku.id}>
                  <Typography variant="body1">{sku.title}</Typography>
                </MenuItem>
              ))}
            </InputField>
          </Grid>

          {/* Size */}
          <Grid item container spacing={2}>
            <Grid item xs={3}>
              {/* Width CM */}
              <InputField
                error={Boolean(
                  formik.touched.publicMetadata?.widthCm && formik.errors.publicMetadata?.widthCm,
                )}
                fullWidth
                helperText={
                  formik.touched.publicMetadata?.widthCm && formik.errors.publicMetadata?.widthCm
                }
                label="Width (cm)"
                name="publicMetadata.widthCm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.publicMetadata?.widthCm}
                type="number"
                disabled={isSizeDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              {/* Height CM */}
              <InputField
                error={Boolean(
                  formik.touched.publicMetadata?.heightCm && formik.errors.publicMetadata?.heightCm,
                )}
                fullWidth
                helperText={
                  formik.touched.publicMetadata?.heightCm && formik.errors.publicMetadata?.heightCm
                }
                label="Height (cm)"
                name="publicMetadata.heightCm"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.publicMetadata?.heightCm}
                type="number"
                disabled={isSizeDisabled}
              />
            </Grid>
          </Grid>

          {/* Is Private */}
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>
                <Typography variant="subtitle2" color="text.primary">
                  Item Visibility
                </Typography>
              </FormLabel>
              <RadioGroup defaultValue={formik.initialValues.visibility} name="visibility">
                <FormControlLabel
                  value={EItemVisibility.PRIVATE}
                  control={<Radio />}
                  label="Private"
                  onChange={formik.handleChange}
                />
                <FormControlLabel
                  value={EItemVisibility.TENANT}
                  control={<Radio />}
                  label="Tenant"
                  onChange={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {formik.errors.submit && (
            <Grid item xs={12}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={formik.isSubmitting}
          color="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="contained"
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ItemCreateDialog.defaultProps = {
  open: false,
};

ItemCreateDialog.propTypes = {
  // @ts-ignore
  item: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
