import { useCallback, FC } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Box, Button, IconButton } from '@material-ui/core';
import { useMutation, useQueryClient } from 'react-query';
import { Item } from '../../../../types/item';
import { ConfirmationDialog } from '../../../../components/confirmation-dialog';
import { useDialog } from '../../../../hooks/use-dialog';
import { Trash as TrashIcon } from '../../../../icons/trash';
import { useAuth } from '../../../../hooks/use-auth';
import logger from '../../../../utils/logger';
import { useAxios } from '../../../../hooks/use-axios';

interface ItemsActionsProps {
  item: Item;
}

export const ItemsActions: FC<ItemsActionsProps> = ({ item }) => {
  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const { tenant } = useAuth();
  const { axios } = useAxios();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    async () => {
      if (!tenant) {
        throw new Error('Tenant is missing');
      }

      const url = `/items/${item.id}`;

      return axios.delete(url);
    },
    {
      onSuccess: () => {
        toast.success('Item was deleted');
        queryClient.invalidateQueries('items');
      },
      onError: (err) => {
        logger(err);
        toast.error('Deleting item failed');
      },
    },
  );

  const handleDeleteItem = useCallback(() => {
    deleteMutation.mutate();
  }, [deleteMutation]);

  return (
    <>
      <Box
        display="flex"
        sx={{
          justifyContent: 'flex-end',
          gridGap: '1rem',
        }}
      >
        <Button
          component={RouterLink}
          to={`/tenants/${tenant?.id}/items/${item.id}`}
          variant="text"
        >
          Preview
        </Button>
        <IconButton
          sx={{
            p: 0,
          }}
          onClick={handleOpenDelete}
        >
          <TrashIcon />
        </IconButton>
      </Box>

      <ConfirmationDialog
        message={`Are you sure you want to delete item with unique ID: „${item.uniqueId}“? This can't be undone.`}
        onCancel={handleCloseDelete}
        onConfirm={handleDeleteItem}
        open={openDelete}
        title="Delete Item"
        variant="error"
      />
    </>
  );
};

ItemsActions.propTypes = {
  item: Proptypes.any.isRequired,
};
