import type { FC } from 'react';
import Proptypes from 'prop-types';
import { Item } from 'src/types/item';
import { Status } from '../../../../components/status';

interface ItemStatusProps {
  item?: Item;
  status?: 'created' | 'protected' | 'registered' | 'protected-registered' | 'error';
}

const statusVariants = [
  {
    color: 'primary.main',
    label: 'Created',
    value: 'created',
  },
  {
    color: 'info.main',
    label: 'Protected',
    value: 'protected',
  },
  {
    color: 'info.main',
    label: 'Registered',
    value: 'registered',
  },
  {
    color: 'info.main',
    label: 'Protected & Registered',
    value: 'protected-registered',
  },
];

const getStatus = (item: Item) => {
  const statusValues = [];
  if (item.isProtected) {
    statusValues.push('protected');
  }
  if (item.isRegistered) {
    statusValues.push('registered');
  }

  return statusValues.join('-') || 'created';
};

export const ItemStatus: FC<ItemStatusProps> = ({ item, status }) => {
  const selectedStatus = item ? getStatus(item) : status;

  const { value, ...statusVariant } = statusVariants.find(
    (variant) => variant.value === selectedStatus,
  );

  return <Status {...statusVariant} />;
};

ItemStatus.propTypes = {
  item: Proptypes.any,
  status: Proptypes.oneOf(['created', 'protected', 'registered', 'protected-registered', 'error']),
};
