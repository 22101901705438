import { FC } from 'react';
import Proptypes from 'prop-types';
import { Item } from '../../types/item';
import { ETemplateType } from '../../templates/types';
import { ItemsActions as ItemsActionsDefault } from '../../templates/default/components/item/items-actions';
import { ItemsActions as ItemsActionsAuthentique } from '../../templates/authentique/components/item/items-actions';
import { ItemsActions as ItemsActionsVera } from '../../templates/vera-admin/components/item/items-actions';

interface ItemsActionsProps {
  item: Item;
}

export const ItemsActions: FC<ItemsActionsProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <ItemsActionsDefault {...props} />;
    case ETemplateType.AUTHENTIQUE:
      return <ItemsActionsAuthentique {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <ItemsActionsVera {...props} />;
  }
};

ItemsActions.propTypes = {
  item: Proptypes.any.isRequired,
};
