import { Vertical } from './vertical';

export const ADMIN_KEY = 'ADMIN';

export const SUPER_ADMIN_TENANT: Tenant = {
  id: ADMIN_KEY,
  title: 'Super Admin',
};

export enum TenantType {
  AUTHENTICATION = 'authentication',
  IDENTIFICATION = 'identification',
}

interface TenantPublicMetadata {
  [key: string]: any;

  demoMode?: {
    enabled: boolean;
    defaultSkuSerialId: string;
  };
}

export interface Tenant {
  id: string;
  title: string;
  allowedVerticals?: Vertical[];
  publicMetadata?: TenantPublicMetadata;
  createdAt?: Date;
}

export interface UserTenants {
  userId: string;
  tenants: Tenant[];
}

export interface UserTenant {
  userId: string;
  tenant: Tenant;
}

export interface TenantsResponseData {
  tenants: Tenant[];
  activeTenants?: Tenant[];
}
