import type { PartialRouteObject } from 'react-router';
import { ETemplateType } from './templates/types';
import routesDefault from './templates/default/routes';
import routesAuthentique from './templates/authentique/routes';
import routesVera from './templates/vera-admin/routes';
import routesVeraAIOperator from './templates/vera-operator/routes';

const getRoutes = (): PartialRouteObject[] => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return routesDefault;
    case ETemplateType.AUTHENTIQUE:
      return routesAuthentique;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return routesVera;
    case ETemplateType.VERA_OPERATOR:
      return routesVeraAIOperator;
  }
};

const routes = getRoutes();

export default routes;
