import type { FC } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from '@material-ui/lab';
import { FilterValue } from '../../types/filter';
import { ETemplateType } from '../../templates/types';
import {
  JobsFilter as JobsFilterDefault,
  getDefaultRange as getDefaultRangeDefault,
} from '../../templates/default/components/job/jobs-filter';
import {
  JobsFilter as JobsFilterVera,
  getDefaultRange as getDefaultRangeVera,
} from '../../templates/vera-admin/components/job/jobs-filter';
import { EJobsView } from '../../utils/jobs-helper';

interface JobsFilterProps {
  disabled: boolean;
  onRangeChange: (newRange: DateRange<Date>) => void;
  onQueryChange: (newQuery: string) => void;
  onViewChange: (newView: EJobsView) => void;
  onFilterChange: (newFilterValue: FilterValue) => void;
  range: DateRange<Date>;
  query: string;
  view: EJobsView;
  filters?: FilterValue[];
}

export const getDefaultRange = () => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return getDefaultRangeDefault();
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return getDefaultRangeVera();
  }
};

export const JobsFilter: FC<JobsFilterProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <JobsFilterDefault {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <JobsFilterVera {...props} />;
  }
};

JobsFilter.propTypes = {
  disabled: PropTypes.bool,
  onRangeChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  range: PropTypes.any,
  query: PropTypes.string,
  view: PropTypes.any,
  filters: PropTypes.any,
};
