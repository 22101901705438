import type { FC } from 'react';
import Proptypes from 'prop-types';
import { Item } from 'src/types/item';
import { ETemplateType } from '../../templates/types';
import { ItemStatus as ItemStatusDefault } from '../../templates/default/components/item/item-status';
import { ItemStatus as ItemStatusVera } from '../../templates/vera-admin/components/item/item-status';

interface ItemStatusProps {
  item?: Item;
  status?: 'created' | 'protected' | 'registered' | 'protected-registered' | 'error';
}

export const ItemStatus: FC<ItemStatusProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <ItemStatusDefault {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <ItemStatusVera {...props} />;
  }
};

ItemStatus.propTypes = {
  item: Proptypes.any,
  status: Proptypes.oneOf(['created', 'protected', 'registered', 'protected-registered', 'error']),
};
