import type { FC, ReactNode, ElementType } from 'react';
import PropTypes from 'prop-types';
import type { ListItemProps } from '@material-ui/core';
import { ETemplateType } from '../templates/types';
import { PropertyListItem as PropertyListItemDefault } from '../templates/default/components/property-list-item';
import { PropertyListItem as PropertyListItemVeraAIOperator } from '../templates/vera-operator/components/property-list-item';

interface PropertyListItemProps extends ListItemProps {
  align?: string;
  children?: ReactNode;
  component?: ElementType;
  label: string;
  value?: string;
  labelColor?: string;
  color?: string;
}

export const PropertyListItem: FC<PropertyListItemProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <PropertyListItemDefault {...props} />;
    case ETemplateType.VERA_OPERATOR:
      return <PropertyListItemVeraAIOperator {...props} />;
  }
};

PropertyListItem.defaultProps = {
  align: 'vertical',
};

PropertyListItem.propTypes = {
  align: PropTypes.string,
  // @ts-ignore
  component: PropTypes.elementType,
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};
