import { FC, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { InfoContainer, InfoType } from '../components/flow/info-container';
import { ActionsContainer, ActionsType } from '../components/flow/actions-container';
import { useScanner } from '../hooks/use-scanner';
import {
  ESocketEvent,
  ScanMessage,
  MessageAction,
  PhotoMessage,
} from '../contexts/scanner-context';
import { useAxios } from '../../../hooks/use-axios';
import {
  Job,
  EJobResultCode,
  EJobStatus,
  EJobTitle,
  CreateJobData,
  CreateTaskData,
  Task,
  JobPublicMetadata,
} from '../../../types/job';
import { RemoteFile } from '../../../types/remote-file';
import { ListResponse, ResponseData } from '../../../types/axios';
import { useAuth } from '../../../hooks/use-auth';
import { ImageContianer } from '../components/flow/image-container';
import { EItemFace } from '../components/flow/image-item';
import logger, { permaLogger } from '../../../utils/logger';
import { useDialog } from '../../../hooks/use-dialog';
import { JobErrorDialog } from '../components/flow/job-error-dialog';
import { BulkCreateAssetRequestData, EAssetTitle, Asset } from '../../../types/asset';
import { CreateItemData, Item } from '../../../types/item';
import { LoadedItem } from '../types/scanned-item';
import { ImageFile } from '../types/image-file';
import { EFingerprintTitle } from '../../../types/fingerprint';
import { useTimeout } from '../../../hooks/use-timeout';
import { LoadingDialog } from '../components/loading-dialog';
import {
  FP_HDR_DIFF_TIMEOUT,
  E_RUNNING_ERROR,
  RETRY_INTERVAL,
  RETRY_COUNT,
} from '../utils/flow-helper';
import * as analyticsHelper from '../utils/analytics-helper';
import { sendSlackError } from '../utils/slack-helper';

const SLACK_ERROR_LOCATION = 'Protection';

export enum ProtectionStep {
  Front = 'front',
  Back = 'back',
  Analyzing = 'analysing',
  Protected = 'protected',
  UnableToProtect = 'unabletoprotected',
}

interface ItemImages {
  frontFP: ImageFile;
  backFP: ImageFile;
  frontImage: ImageFile;
  backImage: ImageFile;
  thumbnail: ImageFile;
}

interface FlowProtectionProps {
  step: ProtectionStep;
}

export const FlowProtection: FC<FlowProtectionProps> = ({ step }) => {
  const [itemImages, setItemImages] = useState<Partial<ItemImages>>();
  const [protectionId, setProtectionId] = useState<string>();
  const [itemId, setItemId] = useState<string>();
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [jobError, setJobError] = useState<boolean>(false);
  const [cancelProtection, setCancelProtection] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());
  const [jobErrorOpen, handleJobErrorOpen, handleJobErrorClose] = useDialog();
  const {
    item,
    socket,
    captureImage,
    loadItem,
    isItemLoading,
    abortImageCapture,
    sendAnalytics,
    sendSlackMessage,
  } = useScanner();
  const { axios } = useAxios();
  const { tenant } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const abortHttpRequests = useCallback(() => {
    abortImageCapture();

    abortController.abort();
    setAbortController(new AbortController());
  }, [abortImageCapture, abortController]);

  const handleMutationError = useCallback((error: any) => {
    logger('[protection/mutation] Error', error);
    if (error?.message !== 'canceled') {
      toast.error('Something went wrong while protecting item');
      setIsImageLoading(false);
      setJobError(true);

      sendSlackError(
        sendSlackMessage,
        'Backend',
        SLACK_ERROR_LOCATION,
        error,
        'Error while protecting item',
      );
    }
  }, []);

  const handleCaptureError = useCallback((error: any) => {
    logger('[protection/capture] Error', error);
    if (error?.message !== 'canceled') {
      toast.error('Something went wrong while capturing image');
      setIsImageLoading(false);
      setJobError(true);

      sendSlackError(
        sendSlackMessage,
        'Camera controller',
        SLACK_ERROR_LOCATION,
        error,
        'Error while capturing image',
      );
    }
  }, []);

  const handleCompletingItemError = useCallback((error: any) => {
    logger('[protection/completing_item] Error', error);
    if (error?.message !== 'canceled' && error?.response?.status !== 404) {
      toast.error('Something went wrong while completing item');
      setIsImageLoading(false);
      setJobError(true);

      sendSlackError(
        sendSlackMessage,
        'Backend',
        SLACK_ERROR_LOCATION,
        error,
        'Error while completing item',
      );
    }
  }, []);

  const hasImageAndNotFP = useCallback(
    (side: ProtectionStep) =>
      itemImages?.[`${side}Image`]?.remoteFile && !itemImages?.[`${side}FP`]?.remoteFile,
    [itemImages],
  );

  // When HDR image is captured but FP is not
  const handleImageAndNotFPTimeout = useCallback(
    (side: ProtectionStep) => {
      if (jobError) {
        return;
      }

      if (itemImages?.[`${side}Image`]?.remoteFile && !itemImages?.[`${side}FP`]?.remoteFile) {
        handleCaptureError(`FP (${side}) photo was not received in time`);
      }
    },
    [jobError, itemImages],
  );

  // Front capture timeout: HDR image was captured but FP is not
  useTimeout(
    () => {
      handleImageAndNotFPTimeout(ProtectionStep.Front);
    },
    hasImageAndNotFP(ProtectionStep.Front) ? FP_HDR_DIFF_TIMEOUT : null,
  );

  // Back capture timeout: HDR image was captured but FP is not
  useTimeout(
    () => {
      handleImageAndNotFPTimeout(ProtectionStep.Back);
    },
    hasImageAndNotFP(ProtectionStep.Back) ? FP_HDR_DIFF_TIMEOUT : null,
  );

  const startJobMutation = useMutation(
    async (newJobId: string) =>
      axios.post<ResponseData<Job>>(`/jobs/${newJobId}/start`, undefined, {
        signal: abortController.signal,
      }),
    {
      onMutate: () => {
        permaLogger('[protection/start_job] Starting job');
      },
      onSuccess: () => {
        permaLogger('[protection/start_job] Job started');
      },
      onError: handleMutationError,
    },
  );

  const { data: job } = useQuery(
    ['job', protectionId, startJobMutation.status],
    async () => {
      if (protectionId && startJobMutation.isSuccess) {
        permaLogger('[protection/job] Fetching job');
        const {
          data: { data: result },
        } = await axios.get<ResponseData<Job>>(`/jobs/${protectionId}`);

        if (result?.status !== EJobStatus.DONE) {
          throw Error(E_RUNNING_ERROR);
        }

        permaLogger('[protection/job] Job completed');
        return result;
      }

      return undefined;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retryDelay: RETRY_INTERVAL,
      retry: RETRY_COUNT,
      onError: (error: any) => {
        if (error?.message === E_RUNNING_ERROR) {
          logger('[protection/job] Job timeout');
          handleMutationError(error);
        }
      },
    },
  );

  const { data: tasks } = useQuery(['tasks', job], async () => {
    if (job?.id) {
      const {
        data: {
          data: { data: result },
        },
      } = await axios.get<ResponseData<ListResponse<Task>>>(`/jobs/${job.id}/tasks`);

      return result;
    }

    return undefined;
  });

  const getResultCode = useCallback(
    (side: ProtectionStep) => {
      if (tasks) {
        const fingerprintTitle =
          side === ProtectionStep.Front
            ? EFingerprintTitle.PROTECTION_FRONT
            : EFingerprintTitle.PROTECTION_BACK;

        const task = tasks.find((t) => t.publicMetadata?.fingerprintTitle === fingerprintTitle);
        if (task) {
          return task.resultCode;
        }
      }

      return undefined;
    },
    [tasks],
  );

  const updateImageMutation = useMutation(async (image: RemoteFile) => {
    const data = {
      uploadTime: image.uploadTime,
    };

    return axios.put(`/files/${image.id}`, data, {
      signal: abortController.signal,
    });
  });

  const createItemMutation = useMutation(
    async (itemData: LoadedItem) => {
      const data: CreateItemData = {
        tenantId: tenant?.id,
        skuId: itemData.sku?.id,
        item: {
          title: itemData.title,
          publicMetadata: itemData.publicMetadata,
        },
      };

      return axios.post<ResponseData<Item>>('/items', data, {
        signal: abortController.signal,
      });
    },
    {
      onMutate: () => {
        permaLogger('[protection/create_item] Creating item');
        setItemId(undefined);
      },
      onSuccess: ({ data: { data } }) => {
        permaLogger('[protection/create_item] Item created');
        setItemId(data.id);
      },
      onError: handleMutationError,
    },
  );

  const createJobMutation = useMutation(
    async (newItemId: string) => {
      const data: CreateJobData = {
        title: EJobTitle.PROTECTION,
        tenantId: tenant?.id,
        itemId: newItemId,
      };

      return axios.post<ResponseData<Job>>('/jobs', data, {
        signal: abortController.signal,
      });
    },
    {
      onMutate: () => {
        permaLogger('[protection/create_job] Creating job');
        setProtectionId(undefined);
      },
      onSuccess: ({ data: { data } }) => {
        permaLogger('[protection/create_job] Job created');
        setProtectionId(data.id);
      },
      onError: handleMutationError,
    },
  );

  const initProtectionMutation = useMutation(async () => {
    createItemMutation.mutate(item, {
      onSuccess: ({ data: { data: newItem } }) => {
        createJobMutation.mutate(newItem.id);
      },
    });
  });

  const createTaskMutation = useMutation(
    async ({
      newJobId,
      image,
      itemSide,
    }: {
      newJobId: string;
      image: RemoteFile;
      itemSide: ProtectionStep;
    }) => {
      if (Number.isFinite(image.uploadTime)) {
        updateImageMutation.mutateAsync(image);
      }

      const data: CreateTaskData = {
        fileId: image.id,
        publicMetadata: {
          fingerprintTitle:
            itemSide === ProtectionStep.Front
              ? EFingerprintTitle.PROTECTION_FRONT
              : EFingerprintTitle.PROTECTION_BACK,
        },
      };

      if (process.env.REACT_APP_DEBUG_MODE && item.debug) {
        data.debug = item.debug;
      }

      return axios.post<ResponseData<Task>>(
        `/jobs/${newJobId}/tasks/authentication/protection`,
        data,
        {
          signal: abortController.signal,
        },
      );
    },
    {
      onMutate: ({ itemSide }) => {
        permaLogger('[protection/create_task] Creating task', itemSide);
      },
      onSuccess: ({ data: { data: newTask } }) => {
        permaLogger(
          '[protection/create_task] Task created',
          newTask.publicMetadata?.fingerprintTitle,
        );
      },
      onError: handleMutationError,
    },
  );

  const protectFrontMutation = useMutation(async (image: RemoteFile) =>
    createTaskMutation.mutateAsync({
      newJobId: protectionId,
      image,
      itemSide: ProtectionStep.Front,
    }),
  );

  const protectBackMutation = useMutation(async (image: RemoteFile) =>
    createTaskMutation.mutateAsync({
      newJobId: protectionId,
      image,
      itemSide: ProtectionStep.Back,
    }),
  );

  const addAssetsMutation = useMutation(
    async () => {
      const { frontImage, thumbnail, backImage } = itemImages;

      const url = `/items/${itemId}/assets/bulk`;
      const data: BulkCreateAssetRequestData = {
        tenantId: tenant.id,
        assets: [
          {
            fileId: frontImage.remoteFile.id,
            title: EAssetTitle.FRONT_IMAGE,
            uploadTime: frontImage.remoteFile.uploadTime,
          },
          {
            fileId: thumbnail.remoteFile.id,
            title: EAssetTitle.FRONT_THUMBNAIL,
            uploadTime: thumbnail.remoteFile.uploadTime,
          },
          {
            fileId: backImage.remoteFile.id,
            title: EAssetTitle.BACK_IMAGE,
            uploadTime: backImage.remoteFile.uploadTime,
          },
        ],
      };

      return axios.post<ResponseData<Asset>>(url, data, {
        signal: abortController.signal,
      });
    },
    {
      onMutate: () => {
        permaLogger('[protection/add_assets] Adding assets to item');
      },
      onSuccess: () => {
        permaLogger('[protection/add_assets] Assets added to item');
      },
      onError: handleCompletingItemError,
    },
  );

  const cancelProtectionMutation = useMutation(
    async () => {
      try {
        if (protectionId) {
          const urlJob = `jobs/${protectionId}`;
          await axios.delete(urlJob);
        }

        if (itemId) {
          const urlItem = `/items/${itemId}`;
          await axios.delete(urlItem);
        }
      } catch (error) {
        if (error?.response?.status !== 409) {
          throw error;
        }
      }
    },
    {
      onSuccess: () => {
        permaLogger('[protection/cancel] Protection canceled');
      },
      onError: (error: any) => {
        logger('[protection/cancel] Error', error?.response?.message || error);
      },
    },
  );

  const sendAnalyticsMutation = useMutation(async () => {
    const elapsedTime = analyticsHelper.getSessionElapsedTimeFromFirstInput();
    const elapsedTimeNoInput =
      analyticsHelper.getSessionElapsedTimeFromFirstInputWithoutUserInput();

    // Send analytics to zabbix
    sendAnalytics({
      key: analyticsHelper.ZABBIX_KEY_PROTECTION_SESSION_ELAPSED_TIME,
      value: elapsedTime,
    });
    sendAnalytics({
      key: analyticsHelper.ZABBIX_KEY_PROTECTION_SESSION_ELAPSED_TIME_NO_INPUT,
      value: elapsedTimeNoInput,
    });

    // Update job metadata
    const url = `jobs/${protectionId}`;

    const data = {
      publicMetadata: {
        elapsedTime,
        elapsedTimeNoInput,
      } as JobPublicMetadata,
    };

    return axios.put(url, data);
  });

  const restartView = useCallback(() => {
    setProtectionId(undefined);
    setItemId(undefined);
    setItemImages(undefined);
    setIsImageLoading(false);
    setJobError(false);
    setCancelProtection(false);
    setAbortController(new AbortController());

    initProtectionMutation.reset();
    startJobMutation.reset();
    createItemMutation.reset();
    createJobMutation.reset();
    protectFrontMutation.reset();
    protectBackMutation.reset();
    addAssetsMutation.reset();
    cancelProtectionMutation.reset();
    sendAnalyticsMutation.reset();

    queryClient.invalidateQueries('job');
  }, []);

  const restartFlow = useCallback(async () => {
    const afterReset = (failed?: boolean) => {
      restartView();

      handleJobErrorClose();
      if (failed) {
        toast.error('Something went wrong while canceling protection');
      } else {
        toast.success('Protection canceled');
      }
      // navigate('/');
      window.location.href = '/';
    };

    // Abort http requests and other image capture
    abortHttpRequests();

    // Cancel if already protecting
    if (protectionId && itemId) {
      cancelProtectionMutation.mutate(undefined, {
        onSuccess: () => {
          afterReset();
        },
        onError: () => {
          afterReset(true);
        },
      });
    } else {
      afterReset();
    }
  }, [protectionId, itemId, abortHttpRequests, restartView]);

  const handleClose = useCallback(() => {
    // Canceled protection
    if (step !== ProtectionStep.Protected && step !== ProtectionStep.UnableToProtect) {
      setCancelProtection(true);
    } else {
      // navigate('/');
      window.location.href = '/';
    }
  }, [step, job]);

  const handleReject = useCallback(() => {
    setCancelProtection(true);
  }, []);

  const captureImageHandler = useCallback(
    (side: ProtectionStep, createThumbnail?: boolean) => {
      const m = item.sku.publicMetadata;
      // SKU Size and Serial ID
      const {
        serialId: skuSerialId,
        width: skuWidth,
        height: skuHeight,
        depth: skuDepth,
        isInSleeve,
      } = m;
      // SKU Front Offset
      let {
        offsetFrontTop: offsetTop,
        offsetFrontRight: offsetRight,
        offsetFrontBottom: offsetBottom,
        offsetFrontLeft: offsetLeft,
      } = m;
      // SKU Back Offset
      if (side === ProtectionStep.Back) {
        offsetTop = m.offsetBackTop;
        offsetRight = m.offsetBackRight;
        offsetBottom = m.offsetBackBottom;
        offsetLeft = m.offsetBackLeft;
      }

      captureImage(
        {
          title: item.title,
          skuSerialId,
          skuWidth,
          skuHeight,
          skuDepth,
          offsetTop,
          offsetRight,
          offsetBottom,
          offsetLeft,
          isInSleeve: isInSleeve?.toString() !== 'false', // Default to true
          isHDR: true,
          side,
          createThumbnail,
          createFP: true,
        },
        (data, error) => {
          if (!data) {
            handleCaptureError(error);
            return;
          }

          permaLogger('[protection/capture] Image captured', data.isHDR ? 'HDR' : 'FP', data.side);

          if (data.isHDR) {
            if (data.side === ProtectionStep.Front) {
              setItemImages((prev) => ({
                ...prev,
                frontImage: {
                  ...prev?.frontImage,
                  remoteFile: data.image,
                  base64: data.previewBase64,
                },
                thumbnail: { remoteFile: data.thumbnail },
              }));
            } else {
              setItemImages((prev) => ({
                ...prev,
                backImage: {
                  ...prev?.backImage,
                  remoteFile: data.image,
                  base64: data.previewBase64,
                },
              }));
            }
          } else {
            handleCaptureError('FP photo was captured in HDR mode');
          }
        },
      );
    },
    [captureImage, item],
  );

  const captureFrontImage = useCallback(() => {
    permaLogger('[protection/capture] Capturing front image');
    analyticsHelper.setCapturingFrontStartTimestamp();

    setIsImageLoading(true);
    captureImageHandler(ProtectionStep.Front, true);
    // Create item and job to save time
    initProtectionMutation.mutate();
  }, [captureImageHandler]);

  const captureBackImage = useCallback(() => {
    permaLogger('[protection/capture] Capturing back image');
    analyticsHelper.setCapturingBackStartTimestamp();

    setIsImageLoading(true);
    captureImageHandler(ProtectionStep.Back);
  }, [captureImageHandler]);

  const messageHandler = useCallback(
    ({ action, data }: ScanMessage) => {
      if (action !== MessageAction.ItemScan) {
        return;
      }

      permaLogger('[protection/scan] Item scanned');

      // Scanning of item when error occured, will close the view
      if (jobError) {
        handleClose();
        return;
      }

      // Scan of item on result screen will load new item
      if (step === ProtectionStep.Protected || step === ProtectionStep.UnableToProtect) {
        if (!isItemLoading) {
          restartView();
          loadItem(data);
        }
        return;
      }

      // Scan of same item will take a photo
      if (
        !isImageLoading &&
        data.uniqueId === item.publicMetadata?.uniqueId &&
        step !== ProtectionStep.Analyzing
      ) {
        if (step === ProtectionStep.Front) {
          captureFrontImage();
        } else {
          captureBackImage();
        }
      }
    },
    [jobError, step, captureImageHandler, isImageLoading],
  );

  const photoMessageHandler = useCallback(
    (data: PhotoMessage) => {
      permaLogger('[protection/photo] Photo captured', data.isHDR ? 'HDR' : 'FP', data.side);

      logger('[protection/photo] Photo captured', data);

      const itemImageType = data.isHDR ? 'Image' : 'FP';
      setItemImages((prev) => ({
        ...prev,
        [`${data.side}${itemImageType}`]: { remoteFile: data.image, base64: data.previewBase64 },
      }));

      if (!data.isHDR) {
        setIsImageLoading(false);
        // Protect front/back after job is created
        // Synced in useEffect bellow
      } else {
        handleCaptureError('FP photo was captured in HDR mode');
      }
    },
    [protectionId],
  );

  const cameraAvailableMessageHandler = useCallback(
    (data: { side: ProtectionStep }) => {
      permaLogger('[protection/camera] Camera available', data.side);

      setIsImageLoading(false);

      if (data.side === ProtectionStep.Front && step !== ProtectionStep.Front) {
        return;
      }

      if (data.side === ProtectionStep.Back && step !== ProtectionStep.Back) {
        return;
      }

      if (data.side === ProtectionStep.Front) {
        analyticsHelper.setCapturingBackAvailableTimestamp();
      } else {
        analyticsHelper.setAnalysingStartTimestamp();
      }

      navigate(data.side === ProtectionStep.Front ? '/protection/back' : '/protection/analyzing', {
        replace: true,
      });
    },
    [step],
  );

  // Protect back after FP image was captured and job is created
  useEffect(() => {
    if (jobError) {
      return;
    }

    if (protectionId) {
      if (itemImages?.frontFP?.remoteFile && protectFrontMutation.isIdle) {
        protectFrontMutation.mutate(itemImages.frontFP.remoteFile);
      }

      if (itemImages?.backFP?.remoteFile && protectBackMutation.isIdle) {
        protectBackMutation.mutate(itemImages.backFP.remoteFile);
      }

      if (
        startJobMutation.isIdle &&
        protectFrontMutation.isSuccess &&
        protectBackMutation.isSuccess
      ) {
        startJobMutation.mutate(protectionId);
      }
    }
  }, [
    jobError,
    itemImages,
    protectionId,
    protectFrontMutation.status,
    protectBackMutation.status,
    startJobMutation.status,
  ]);

  useEffect(() => {
    if (jobError) {
      abortHttpRequests();
      handleJobErrorOpen();
    } else {
      handleJobErrorClose();
    }
  }, [jobError]);

  useEffect(() => {
    restartView();
  }, [item, restartView]);

  useEffect(() => {
    if (jobError) {
      return;
    }

    if (
      !jobError &&
      itemImages?.frontImage?.remoteFile &&
      itemImages?.backImage?.remoteFile &&
      itemId &&
      addAssetsMutation.isIdle
    ) {
      addAssetsMutation.mutate();
    }
  }, [jobError, itemImages, job, addAssetsMutation]);

  useEffect(() => {
    if (jobError) {
      return;
    }

    if (
      step === ProtectionStep.Analyzing &&
      job?.status === EJobStatus.DONE &&
      addAssetsMutation.isSuccess
    ) {
      permaLogger('[protection/result] Everything done, going to result screen');
      analyticsHelper.setAnalysingEndTimestamp();
      analyticsHelper.setSessionEndTimestamp();
      sendAnalyticsMutation.mutate();

      switch (job.resultCode) {
        case EJobResultCode.OK:
          navigate('/protection/success', { replace: true });
          break;
        default:
          navigate('/protection/fail', { replace: true });
          break;
      }
    }
  }, [jobError, step, job, addAssetsMutation]);

  useEffect(() => {
    socket?.on(ESocketEvent.ScanEvent, messageHandler);
    socket?.on(ESocketEvent.PhotoCapturedEvent, photoMessageHandler);
    socket?.on(ESocketEvent.CameraAvailableEvent, cameraAvailableMessageHandler);
    return () => {
      socket?.off(ESocketEvent.ScanEvent, messageHandler);
      socket?.off(ESocketEvent.PhotoCapturedEvent, photoMessageHandler);
      socket?.off(ESocketEvent.CameraAvailableEvent, cameraAvailableMessageHandler);
    };
  }, [socket, messageHandler]);

  // Canceling process
  useEffect(() => {
    if (cancelProtection) {
      setCancelProtection(false);

      if (initProtectionMutation.isIdle && !protectionId && !isImageLoading) {
        // navigate('/');
        window.location.href = '/';

        return;
      }

      restartFlow();
    }
  }, [cancelProtection, initProtectionMutation.isIdle, protectionId, itemId, restartFlow]);

  if (!item) {
    return <Navigate to="/" replace />;
  }
  const getContent = () => {
    switch (step) {
      case ProtectionStep.Front:
        return (
          <>
            <ImageContianer
              face={EItemFace.Front}
              isImageLoading={isImageLoading}
              frontImageBase64={itemImages?.frontImage?.base64 || itemImages?.frontFP?.base64}
            />
            <InfoContainer type={InfoType.Protection} item={item} showClose onClose={handleClose} />
            <ActionsContainer
              id="protection-actions-button-capture-front"
              type={ActionsType.CaptureFront}
              onClick={captureFrontImage}
              isLoading={isImageLoading}
            />
          </>
        );
      case ProtectionStep.Back:
        return (
          <>
            <ImageContianer
              face={EItemFace.Back}
              isImageLoading={isImageLoading}
              frontImageBase64={itemImages?.frontImage?.base64 || itemImages?.frontFP?.base64}
              backImageBase64={itemImages?.backImage?.base64 || itemImages?.backFP?.base64}
            />
            <InfoContainer type={InfoType.Protection} item={item} showClose onClose={handleClose} />
            <ActionsContainer
              id="protection-actions-button-capture-back"
              type={ActionsType.CaptureBack}
              onClick={captureBackImage}
              isLoading={isImageLoading}
            />
          </>
        );
      case ProtectionStep.Analyzing:
        return (
          <>
            <ImageContianer
              frontImageBase64={itemImages?.frontImage?.base64 || itemImages?.frontFP?.base64}
              backImageBase64={itemImages?.backImage?.base64 || itemImages?.backFP?.base64}
            />
            <InfoContainer type={InfoType.Protection} item={item} showClose onClose={handleClose} />
            <ActionsContainer id="protection-actions-analyzing" type={ActionsType.Analyzing} />
          </>
        );
      case ProtectionStep.Protected:
        return (
          <>
            <ImageContianer
              frontResultCode={getResultCode(ProtectionStep.Front)}
              backResultCode={getResultCode(ProtectionStep.Back)}
              frontImageBase64={itemImages?.frontImage?.base64}
              backImageBase64={itemImages?.backImage?.base64}
            />
            <InfoContainer type={InfoType.Protection} item={item} />
            <ActionsContainer
              id="protection-actions-button-protected"
              type={ActionsType.Protected}
              onClick={handleClose}
              onRejectClick={handleReject}
            />
          </>
        );
      case ProtectionStep.UnableToProtect:
        return (
          <>
            <ImageContianer
              frontResultCode={getResultCode(ProtectionStep.Front)}
              backResultCode={getResultCode(ProtectionStep.Back)}
              frontImageBase64={itemImages?.frontImage?.base64}
              backImageBase64={itemImages?.backImage?.base64}
            />
            <InfoContainer type={InfoType.Protection} item={item} />
            <ActionsContainer
              id="protection-actions-button-unable-to-protect"
              type={ActionsType.UnableToProtect}
              onClick={handleClose}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {getContent()}
      <JobErrorDialog open={jobErrorOpen} onClose={handleClose} />
      <LoadingDialog
        open={cancelProtectionMutation.isLoading}
        title={`${
          [ProtectionStep.Protected, ProtectionStep.UnableToProtect].includes(step)
            ? 'Rejecting'
            : 'Canceling'
        } protection`}
      />
    </>
  );
};

FlowProtection.propTypes = {
  step: PropTypes.any.isRequired,
};
