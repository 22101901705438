import type { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';
import { Item } from '../../../../types/item';
import { Pagination } from '../../../../components/pagination';
import { ResourceError } from '../../../../components/resource-error';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { Ban as DeactivateItemIcon } from '../../../../icons/ban';
import { getThumbnailUrl } from '../../../../utils/assets-helper';
import { Sort } from '../../../../types/filter';
import { useScanner } from '../../hooks/use-scanner';

interface ItemsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  items: Item[];
  itemsCount: number;
  sort: Sort;
  sortBy: string;
}

const columns = [
  {
    id: 'title',
    label: 'Title/UID',
  },
  {
    id: 'sku',
    label: 'SKU Title',
    sortable: false,
  },
  {
    id: 'createdAt',
    label: 'Protected',
  },
];

export const ItemsTable: FC<ItemsTableProps> = (props) => {
  const { error, isLoading, onPageChange, onSortChange, page, items, itemsCount, sort, sortBy } =
    props;
  const { setItem } = useScanner();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !items.length);

  const handleRowClick = (item: Item) => {
    setItem(item);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Table id="activity-table" stickyHeader sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>
                <TableSortLabel
                  active={sortBy === column.id}
                  direction={sortBy === column.id ? sort : 'asc'}
                  disabled
                  onClick={(event) => onSortChange(event, column.id)}
                  sx={{
                    background: 'transparent',
                  }}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow
              id={`items-table-row-${item.id}`}
              hover
              key={item.uniqueId}
              onClick={() => handleRowClick(item)}
              sx={{
                height: 'calc((100vh - 342px) / 5)',
                '.MuiTableCell-root': {
                  py: 0,
                },
              }}
            >
              {/* Title + UID */}
              <TableCell>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Avatar
                    alt={item.title}
                    src={getThumbnailUrl(item)}
                    sx={{
                      width: 129,
                      height: 129,
                      boxShadow: '0 0 1px black',
                    }}
                    variant="rounded"
                  >
                    <ImageNotSupportedIcon fontSize="small" />
                  </Avatar>
                  <Box sx={{ ml: 2 }}>
                    <Box>
                      <Box display="flex">
                        {item.publicMetadata?.deactivated && (
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              mr: 1,
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            title="Deactivated item"
                          >
                            <DeactivateItemIcon />
                          </Typography>
                        )}
                        <Typography variant="subtitle2">{item.publicMetadata?.uniqueId}</Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        noWrap
                        maxWidth={{
                          md: 200,
                          lg: 350,
                          xl: 650,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TableCell>
              {/* SKU */}

              <TableCell>
                <Typography
                  variant="body2"
                  color="inherit"
                  noWrap
                  maxWidth={{
                    md: 200,
                    lg: 350,
                    xl: 650,
                  }}
                >
                  {item.sku?.title}
                </Typography>
              </TableCell>

              {/* Created / Protected / Registered */}
              <TableCell>
                {(item.protectedAt ?? item.registeredAt ?? item.createdAt) && (
                  <Box>
                    <Typography variant="body2">
                      {item.protectedBy?.email || item.registeredBy?.email}
                    </Typography>
                    <Typography color="inherit" variant="body2">
                      {format(
                        new Date(item.protectedAt ?? item.registeredAt ?? item.createdAt),
                        'h:mmaaa MM/dd/yyyy',
                      )}
                    </Typography>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={itemsCount}
      />
    </Box>
  );
};

ItemsTable.defaultProps = {
  page: 1,
  items: [],
  itemsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
};

ItemsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  items: Proptypes.array,
  itemsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
};
