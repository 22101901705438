import type { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { Item } from '../../types/item';
import { Sort } from '../../types/filter';
import { ETemplateType } from '../../templates/types';
import { ItemsTable as ItemsTableDefault } from '../../templates/default/components/item/items-table';
import { ItemsTable as ItemsTableVera } from '../../templates/vera-admin/components/item/items-table';
import { ItemsTable as ItemsTableVeraOperator } from '../../templates/vera-operator/components/item/items-table';

interface ItemsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  items: Item[];
  itemsCount: number;
  sort: Sort;
  sortBy: string;
  location?: 'items' | 'sku';
}

export const ItemsTable: FC<ItemsTableProps> = (props) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return <ItemsTableDefault {...props} />;
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
      return <ItemsTableVera {...props} />;
    case ETemplateType.VERA_OPERATOR:
      return <ItemsTableVeraOperator {...props} />;
  }
};

ItemsTable.defaultProps = {
  page: 1,
  items: [],
  itemsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
  location: 'items',
};

ItemsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  items: Proptypes.array,
  itemsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  location: Proptypes.oneOf(['items', 'sku']),
};
