import { useEffect, useState } from 'react';
import type { FC, MouseEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Card, Container, Divider, Typography } from '@material-ui/core';
import { ItemsTable } from '../../../components/item/items-table';
import type { Item } from '../../../types/item';
import { useAxios } from '../../../hooks/use-axios';
import { useAuth } from '../../../hooks/use-auth';
import { ItemsFilter } from '../../../components/item/items-filter';
import type { ListResponse, ResponseData } from '../../../types/axios';
import { getHelmetTitle } from '../../../utils/utils';
import { FilterValue, Sort } from '../../../types/filter';
import { storage } from '../../../utils/storage';

type ItemsData = ListResponse<Item>;

interface Controller {
  filters: Array<FilterValue>;
  page: number;
  query: string;
  sort: Sort;
  sortBy: string;
}

const DEFAULT_CONTROLLER: Controller = {
  filters: [],
  page: 0,
  query: '',
  sort: 'desc',
  sortBy: 'createdAt',
};

const setDefaultControllerFromSearchParams = (searchParams: URLSearchParams): Controller => {
  const storedFilters: Controller | undefined = JSON.parse(storage.controllers.get() || '{}').items;

  let { filters } = DEFAULT_CONTROLLER;
  // Filters in search params or local storage
  filters =
    (searchParams.get('filters')
      ? JSON.parse(searchParams.get('filters') || '[]')
      : storedFilters?.filters) || filters;

  return {
    filters,
    page: Number(searchParams.get('page')) || storedFilters?.page || DEFAULT_CONTROLLER.page,
    query: searchParams.get('query') ?? storedFilters?.query ?? DEFAULT_CONTROLLER.query,
    sort: (searchParams.get('sort') as Sort) || storedFilters?.sort || DEFAULT_CONTROLLER.sort,
    sortBy: searchParams.get('sortBy') || storedFilters?.sortBy || DEFAULT_CONTROLLER.sortBy,
  };
};

export const Items: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [controller, setController] = useState<Controller>(
    setDefaultControllerFromSearchParams(searchParams),
  );

  const { axios } = useAxios();
  const { tenant } = useAuth();

  const { data, isLoading, error } = useQuery<ItemsData>(
    ['items', tenant, controller],
    async () => {
      if (tenant) {
        const params: any = {
          start: controller.page * 10,
          length: 10,
          sort: controller.sort.toUpperCase(),
          sortBy: controller.sortBy,
          'globalFilter[tenant]': tenant?.id,
        };

        if (controller.query) {
          params['search[title]'] = controller.query;
          params['search[uniqueId]'] = controller.query;
          params['search[sku.title]'] = controller.query;
        }

        if (controller.filters.length) {
          controller.filters.forEach((f) => {
            params[`globalFilter[${f.property}]`] = {
              operator: f.operator,
              value: f.value,
            };
          });
        }

        const url = `/items`;

        const { data: response } = await axios.get<ResponseData<ItemsData>>(url, { params });

        return response.data;
      }
      throw new Error('Tenant is missing');
    },
  );

  const handlePageChange = (newPage: number): void => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handleQueryChange = (newQuery: string): void => {
    setController({
      ...controller,
      page: 0,
      query: newQuery,
    });
  };

  const handleSortChange = (event: MouseEvent<HTMLElement>, property: string): void => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handleFilterChange = (newFilterValue: FilterValue): void => {
    let updatedFilters = controller.filters.filter((f) => f.property !== newFilterValue.property);
    if (newFilterValue.value) {
      updatedFilters = [...updatedFilters, newFilterValue];
    }

    setController({ ...controller, filters: updatedFilters });
  };

  useEffect(() => {
    const { filters, page, ...rest } = controller;
    setSearchParams({
      ...rest,
      filters: JSON.stringify(filters),
      page: page.toString(),
    });

    return () => {
      const allFilters = JSON.parse(storage.controllers.get() || '{}');
      storage.controllers.set(JSON.stringify({ ...allFilters, items: controller }));
    };
  }, [controller]);

  useEffect(() => {
    setController(setDefaultControllerFromSearchParams(searchParams));
  }, [tenant]);

  return (
    <>
      <Helmet>
        <title>Item: List | {getHelmetTitle()}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: 43,
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Items
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <ItemsFilter
              disabled={isLoading}
              onQueryChange={handleQueryChange}
              onFilterChange={handleFilterChange}
              query={controller.query}
              filters={controller.filters}
            />
            <Divider />
            <ItemsTable
              error={error?.toString()}
              isLoading={isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              items={data?.data}
              itemsCount={data?.recordsFiltered}
              sort={controller.sort}
              sortBy={controller.sortBy}
            />
          </Card>
        </Container>
      </Box>
    </>
  );
};
