import type { FC } from 'react';
import { v4 } from 'uuid';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, Toolbar, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import toast from 'react-hot-toast';
import { AccountPopover } from '../../../../components/account-popover';
import { Logo } from '../../../../components/logo';
import { useAuth } from '../../../../hooks/use-auth';
import { Plus as PlusIcon } from '../../../../icons/plus';
import { useScanner } from '../../hooks/use-scanner';

export const ItemsNavbar: FC = () => {
  const { user: currentUser, tenant } = useAuth();
  const { loadItem, isItemLoading } = useScanner();

  const handleCreateItem = () => {
    if (!tenant?.publicMetadata?.demoMode?.defaultSkuSerialId) {
      toast.error('SKU is not set up in the tenant settings.');
      return;
    }

    const uniqueId = v4() as string;
    loadItem({
      skuSerialId: tenant.publicMetadata.demoMode.defaultSkuSerialId!,
      title: `Item ${uniqueId}`,
      uniqueId,
    });
  };

  return (
    <AppBar elevation={0} sx={{ color: 'text.primary', backgroundColor: '#F2F2F2' }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'normal',
          display: 'flex',
          minHeight: '120px !important',
          pl: 3,
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: 90,
          }}
        >
          <Logo emblemOnly variant="dark" size="large" />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            // borderWidth: '2px',
            borderColor: '#D9D9D9',
            mx: 3,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography id="items-navbar-title" variant="h5">
            Items
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid #999999',
              borderRadius: 1,
            }}
          >
            <LoadingButton
              id="items-navbar-button-create-item"
              onClick={handleCreateItem}
              color="primary"
              size="large"
              loading={isItemLoading}
              variant="contained"
              sx={{
                px: 5,
                py: 2,
              }}
            >
              <PlusIcon
                sx={{
                  ml: '-0.25em',
                  fontSize: '1.5em',
                }}
              />
              Create Item
            </LoadingButton>
          </Box>
        </Box>
        <AccountPopover currentUser={currentUser} />
      </Toolbar>
      <Divider
        sx={{
          borderColor: '#D9D9D9',
        }}
      />
    </AppBar>
  );
};
