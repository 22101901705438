import { createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import type { Direction, Theme } from '@material-ui/core';
import { themeOptions as themeOptionsDefault } from './templates/default/theme';
import { themeOptions as themeOptionsVeraAIOperator } from './templates/vera-operator/theme';
import { ETemplateType } from './templates/types';

interface ThemeConfig {
  direction?: Direction;
  theme?: string;
}

const getThemeOptionsSet = () => {
  switch (process.env.REACT_APP_TEMPLATE) {
    default:
    case ETemplateType.DEFAULT:
      return themeOptionsDefault;
    case ETemplateType.VERA_OPERATOR:
      return themeOptionsVeraAIOperator;
  }
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  const themeOptionsSet = getThemeOptionsSet();
  let themeOptions = config.theme === 'light' ? themeOptionsSet.light : themeOptionsSet.dark;

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themeOptionsSet.light;
  }

  const theme = responsiveFontSizes(
    createMuiTheme(
      { ...themeOptionsSet.base },
      { ...themeOptions },
      {
        direction: config.direction,
      },
    ),
  );

  return theme;
};
