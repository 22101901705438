import type { FC, MouseEvent } from 'react';
import Proptypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Avatar,
  Box,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import ImageNotSupportedIcon from '@material-ui/icons/ImageNotSupported';
import { Item } from '../../../../types/item';
import { Pagination } from '../../../../components/pagination';
import { ResourceError } from '../../../../components/resource-error';
import { ResourceUnavailable } from '../../../../components/resource-unavailable';
import { Scrollbar } from '../../../../components/scrollbar';
import { ItemStatus } from '../../../../components/item/item-status';
import { useAuth } from '../../../../hooks/use-auth';
import { getThumbnailUrl } from '../../../../utils/assets-helper';
import { ItemsActions } from '../../../../components/item/items-actions';
import { Sort } from '../../../../types/filter';

interface ItemsTableProps {
  error: string;
  isLoading: boolean;
  onPageChange: (newPage: number) => void;
  onSortChange: (event: MouseEvent<HTMLElement>, property: string) => void;
  page: number;
  items: Item[];
  itemsCount: number;
  sort: Sort;
  sortBy: string;
  location?: 'items' | 'sku';
}

const columns = [
  {
    id: 'title',
    label: 'Title/UID',
  },
  {
    id: 'sku',
    label: 'SKU Title',
    sortable: false,
  },
  {
    id: 'createdAt',
    label: 'Created/Protected',
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
  },
];

export const ItemsTable: FC<ItemsTableProps> = (props) => {
  const {
    error,
    isLoading,
    onPageChange,
    onSortChange,
    page,
    items,
    itemsCount,
    sort,
    sortBy,
    location,
  } = props;

  const { tenant } = useAuth();

  const displayLoading = isLoading;
  const displayError = Boolean(!isLoading && error);
  const displayUnavailable = Boolean(!isLoading && !error && !items.length);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <Scrollbar>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              {columns
                .filter((c) => location !== 'sku' || c.id !== 'sku')
                .map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sort : 'asc'}
                      disabled={isLoading || column.sortable === false}
                      onClick={(event) => onSortChange(event, column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              <TableCell width={120} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow hover key={item.uniqueId}>
                {/* Title + UID */}
                <TableCell>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                    }}
                  >
                    <Avatar
                      alt={item.title}
                      src={getThumbnailUrl(item)}
                      sx={{
                        width: 64,
                        height: 64,
                        '& > img': {
                          objectFit: 'contain',
                        },
                      }}
                      variant="rounded"
                    >
                      <ImageNotSupportedIcon fontSize="small" />
                    </Avatar>
                    <Box sx={{ ml: 2 }}>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                        to={`/tenants/${tenant?.id}/items/${item.id}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            noWrap
                            maxWidth={{
                              md: 200,
                              lg: 350,
                              xl: 650,
                            }}
                            sx={{
                              textDecoration: 'underline',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {item.uniqueId}
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </TableCell>
                {/* SKU */}
                {location !== 'sku' && (
                  <TableCell>
                    <Link
                      color="inherit"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                      to={
                        item.sku ? `/tenants/${tenant?.id}/skus/${item.sku?.id}?source=items` : ''
                      }
                      underline="none"
                      variant="subtitle2"
                    >
                      <Typography
                        variant="body2"
                        color="inherit"
                        noWrap
                        maxWidth={{
                          md: 200,
                          lg: 350,
                          xl: 650,
                        }}
                        sx={{
                          textDecoration: item.sku ? 'underline' : 'none',
                        }}
                      >
                        {item.sku?.title}
                      </Typography>
                    </Link>
                  </TableCell>
                )}
                {/* Created / Protected / Registered */}
                <TableCell>
                  {(item.protectedAt ?? item.registeredAt ?? item.createdAt) && (
                    <Box>
                      <Typography variant="body2">
                        {item.protectedBy?.email || item.registeredBy?.email}
                      </Typography>
                      <Typography color="inherit" variant="body2">
                        {format(
                          new Date(item.protectedAt ?? item.registeredAt ?? item.createdAt),
                          'h:mmaaa MM/dd/yyyy',
                        )}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                {/* Status */}
                <TableCell>
                  <ItemStatus item={item} />
                </TableCell>
                {/* Menu */}
                <TableCell align="right">
                  <ItemsActions item={item} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      {displayLoading && (
        <Box sx={{ p: 2 }}>
          <Skeleton height={42} />
          <Skeleton height={42} />
          <Skeleton height={42} />
        </Box>
      )}
      {displayError && (
        <ResourceError
          error={error}
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      {displayUnavailable && (
        <ResourceUnavailable
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        />
      )}
      <Divider sx={{ mt: 'auto' }} />
      <Pagination
        disabled={isLoading}
        onPageChange={onPageChange}
        page={page}
        rowsCount={itemsCount}
      />
    </Box>
  );
};

ItemsTable.defaultProps = {
  page: 1,
  items: [],
  itemsCount: 0,
  sort: 'desc',
  sortBy: 'createdAt',
  location: 'items',
};

ItemsTable.propTypes = {
  error: Proptypes.string,
  isLoading: Proptypes.bool,
  onPageChange: Proptypes.func,
  onSortChange: Proptypes.func,
  page: Proptypes.number,
  items: Proptypes.array,
  itemsCount: Proptypes.number,
  sort: Proptypes.oneOf(['asc', 'desc']),
  sortBy: Proptypes.string,
  location: Proptypes.oneOf(['items', 'sku']),
};
