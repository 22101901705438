import { ETemplateType } from '../templates/types';
import { SUPER_ADMIN_TENANT, Tenant } from '../types/tenant';
import { Permissions } from '../types/user';

const getTenants = (tenants: Tenant[]) => tenants;

const getTenantsWithSuperadmin = (tenants: Tenant[], permissions: Permissions) => {
  if (permissions.ADMIN && tenants.every((t) => t.id !== SUPER_ADMIN_TENANT.id)) {
    return [SUPER_ADMIN_TENANT, ...tenants];
  }

  return tenants;
};

export const getCorrectTenants = (tenants: Tenant[], permissions: Permissions) => {
  switch (process.env.REACT_APP_TEMPLATE as ETemplateType) {
    default:
    case ETemplateType.DEFAULT:
      return getTenantsWithSuperadmin(tenants, permissions);
    case ETemplateType.AUTHENTIQUE:
    case ETemplateType.EBAY:
    case ETemplateType.VERA_ADMIN:
    case ETemplateType.VERA_OPERATOR:
      return getTenants(tenants);
  }
};
